LT.single_residence = {
  init: function() {
    this.attachHandlers();
    LT.utils.revealImages();
  },
  attachHandlers: function() {
    function removeClass(els) {
      for (var i = 0, l = els.length; i < l; i++) {
        els[i].classList.remove('active');
      }
    }

    function toggleFilters(filter, el, cb) {
      var isFilter = el.classList.contains(filter);

      if (isFilter) {
        var filters = document.querySelectorAll('.' + filter);

        removeClass(filters);
        el.classList.add('active');
        cb(el);
      }
    }

    // Change Floorplans and return the current active one
    function changeFloorplans(typology) {
      var floorplans = document.querySelectorAll('.js-floorplans');
      var activeFloorplan;
      var floorplan;

      for (var i = 0, l = floorplans.length; i < l; i++) {
        floorplan = floorplans[i];

        if (floorplan.dataset.typology === typology) {
          floorplan.classList.remove('hidden');

          if (!activeFloorplan) {
            activeFloorplan = floorplan;
          }
        } else {
          floorplan.classList.add('hidden');
        }
      }

      return activeFloorplan;
    }

    // Change the details and the unit image
    function toggleDetails() {
      var typology = document.querySelector('.js-typology.active').value;
      var activeUnit = document.querySelector('.js-unit.active');
      var unit = activeUnit.value;
      var unitPdf = activeUnit.dataset.pdf;
      var isSoldOut = activeUnit.classList.contains('is-sold-out');
      var details = document.querySelectorAll('.js-details');
      var floorplanImages = document.querySelectorAll('.js-floorplan-image');
      var pdf = document.querySelector('.js-pdf');

      removeClass(details);
      removeClass(floorplanImages);

      document.querySelector('.js-details[data-unit="' + unit + '"][data-typology="' + typology + '"]')
        .classList
        .add('active');

      document.querySelector('.js-floorplan-image[data-unit="' + unit + '"][data-typology="' + typology + '"]')
        .classList
        .add('active');

      if (isSoldOut) {
        pdf.classList.add('hidden');
      } else {
        pdf.classList.remove('hidden');
        pdf.setAttribute('href', unitPdf);
      }
    }

    // Changing typology also changes the floorplans units list as well as the active unit
    function changeTypology(el) {
      var typology = el.value;
      var activeFloorplan = changeFloorplans(typology);
      var firstFloorplanUnit = activeFloorplan.querySelector('.js-unit');

      toggleFilters('js-unit', firstFloorplanUnit, toggleDetails);
    }

    function toggleFloorplan(filter) {
      filter.addEventListener('click', function(e) {
        e.preventDefault();

        toggleFilters('js-typology', this, changeTypology);
        toggleFilters('js-unit', this, toggleDetails);
      });
    }

    LT.utils.toggleElement('.js-typology', toggleFloorplan);
    LT.utils.toggleElement('.js-unit', toggleFloorplan);
  }
};
