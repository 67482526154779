var mapStyle = [{
  "featureType": "administrative",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "administrative.country",
  "elementType": "all",
  "stylers": [{
    "visibility": "on"
  }]
}, {
  "featureType": "administrative.province",
  "elementType": "all",
  "stylers": [{
    "visibility": "on"
  }]
}, {
  "featureType": "administrative.locality",
  "elementType": "all",
  "stylers": [{
    "visibility": "on"
  }]
}, {
  "featureType": "administrative.neighborhood",
  "elementType": "all",
  "stylers": [{
    "visibility": "simplified"
  }]
}, {
  "featureType": "administrative.neighborhood",
  "elementType": "geometry",
  "stylers": [{
    "visibility": "simplified"
  }]
}, {
  "featureType": "administrative.neighborhood",
  "elementType": "geometry.stroke",
  "stylers": [{
    "visibility": "on"
  }, {
    "lightness": "-57"
  }]
}, {
  "featureType": "administrative.land_parcel",
  "elementType": "all",
  "stylers": [{
    "visibility": "on"
  }]
}, {
  "featureType": "administrative.land_parcel",
  "elementType": "labels.text.fill",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "landscape",
  "elementType": "all",
  "stylers": [{
    "saturation": 43.400000000000006
  }, {
    "lightness": 37.599999999999994
  }, {
    "gamma": 1
  }, {
    "visibility": "on"
  }]
}, {
  "featureType": "landscape.man_made",
  "elementType": "all",
  "stylers": [{
    "saturation": "5"
  }, {
    "lightness": "67"
  }]
}, {
  "featureType": "landscape.man_made",
  "elementType": "geometry",
  "stylers": [{
    "color": "#f7efee"
  }]
}, {
  "featureType": "landscape.man_made",
  "elementType": "geometry.fill",
  "stylers": [{
    "saturation": "-77"
  }, {
    "hue": "#ff0000"
  }]
}, {
  "featureType": "landscape.man_made",
  "elementType": "geometry.stroke",
  "stylers": [{
    "saturation": "1"
  }]
}, {
  "featureType": "landscape.natural.terrain",
  "elementType": "geometry.fill",
  "stylers": [{
    "visibility": "off"
  }, {
    "color": "#f6f5f2"
  }]
}, {
  "featureType": "poi",
  "elementType": "all",
  "stylers": [{
    "hue": "#00ff6a"
  }, {
    "saturation": -1.0989010989011234
  }, {
    "lightness": 11.200000000000017
  }, {
    "gamma": 1
  }, {
    "visibility": "on"
  }]
}, {
  "featureType": "poi.attraction",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "poi.attraction",
  "elementType": "labels",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "poi.business",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "poi.business",
  "elementType": "labels",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "poi.government",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "poi.medical",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "poi.park",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#cbdfab"
  }]
}, {
  "featureType": "poi.place_of_worship",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "poi.school",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "poi.sports_complex",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "poi.sports_complex",
  "elementType": "labels",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "road",
  "elementType": "all",
  "stylers": [{
    "visibility": "on"
  }]
}, {
  "featureType": "road.highway",
  "elementType": "all",
  "stylers": [{
    visibility: 'off'
    //"hue": "#FFC200"
  }, {
    "saturation": -61.8
  }, {
    "lightness": 45.599999999999994
  }, {
    "gamma": 1
  }]
}, {
  "featureType": "road.arterial",
  "elementType": "all",
  "stylers": [{
    "hue": "#FF0300"
  }, {
    "saturation": -100
  }, {
    "lightness": 51.19999999999999
  }, {
    "gamma": 1
  }]
}, {
  "featureType": "road.arterial",
  "elementType": "labels",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "road.local",
  "elementType": "all",
  "stylers": [{
    "hue": "#FF0300"
  }, {
    "saturation": -100
  }, {
    "lightness": 52
  }, {
    "gamma": 1
  }]
}, {
  "featureType": "road.local",
  "elementType": "labels",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "road.local",
  "elementType": "labels.icon",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "transit",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "transit",
  "elementType": "labels.icon",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "water",
  "elementType": "all",
  "stylers": [{
    "hue": "#0078FF"
  }, {
    "saturation": -13.200000000000003
  }, {
    "lightness": 2.4000000000000057
  }, {
    "gamma": 1
  }]
}];

var clusterStyles = [{
  url: 'https://s3-eu-west-1.amazonaws.com/liberdade-terrace/dot-blue.png',
  height: 48,
  width: 48,
  textColor: '#ffffff',
  textSize: 20
}];

var map;
var markers = [];
var infoboxes = [];
var activeFilters = [];
var clusterer;
var LTCoords = {
  lat: 38.721333,
  lng: -9.148165
};

LT.location = {
  init: function() {
    var isMobile = document.querySelector('.js-neighborhood-mobile');
    var self = this;

    if (isMobile) {
      LT.utils.collapse('.js-pois-toggle');
    } else {
      var setHeight = function() {
        var wH = window.outerHeight;
        document.querySelector('html').setAttribute('style', 'height: ' + wH + 'px');
      };

      this.initTutorial();
      this.createMap();
      this.setBoundaries();
      this.addLTMarker();
      this.addLisbonMarkers();
      this.addPOIMarkers();
      this.createClusterers();
      this.attachFiltersHandlers();

      google.maps.event.addListenerOnce(map, 'idle', function() {
        self.setActiveFilter();
      });

      window.onresize = setHeight;
      setHeight();
    }
  },
  initTutorial: function() {
    var tutorial = document.querySelector('.js-tutorial');
    var tutorialBtn = document.querySelector('.js-tutorial-btn');

    tutorialBtn.addEventListener('click', function() {
      tutorial.classList.add('hide');
    });
  },
  createMap: function() {
    var styledMap = new google.maps.StyledMapType(mapStyle, {name: 'Styled Map'});
    var myLatlng = new google.maps.LatLng(LTCoords.lat, LTCoords.lng);
    var mapOptions = {
      disableDefaultUI: true,
      zoom: 18,
      minZoom: 15,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.SATELLITE
    };

    map = new google.maps.Map(document.querySelector('.js-map'), mapOptions);

    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');
  },
  setBoundaries: function() {
    // bounds of the desired area
    var allowedBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(38.71, -9.155),
      new google.maps.LatLng(38.73, -9.14)
    );
    var lastValidCenter = map.getCenter();

    google.maps.event.addListener(map, 'center_changed', function() {
      if (allowedBounds.contains(map.getCenter())) {
        // still within valid bounds, so save the last valid position
        lastValidCenter = map.getCenter();

        return;
      }

      // not valid anymore => return to last valid position
      map.panTo(lastValidCenter);
    });
  },
  addLTMarker: function() {
    var markerType = '<svg class="icon-logo icon-logo--small icon--light">' +
        '<use xlink:href="#svgs--logo"></use>' +
      '</svg>';

    var LTMarker = new RichMarker({
      position: new google.maps.LatLng(LTCoords.lat, LTCoords.lng),
      map: map,
      content: '<div class="marker marker--brand marker--LT"><div class="marker__icon">' + markerType + '</div></div>'
    });
  },
  addLisbonMarkers: function() {
    if (lisbonAreas.length) {
      for (var i = 0, l = lisbonAreas.length; i < l; i++) {
        new RichMarker({
          position: new google.maps.LatLng(lisbonAreas[i].lat, lisbonAreas[i].lng),
          map: map,
          content: '<div class="marker marker--brand">' +
             '<a ' + (lisbonAreas[i].link ? 'href="http://' + lisbonAreas[i].link + '" target="_blank"' : '') + '>' + lisbonAreas[i].name + '</a></div>'
        });
      }
    }
  },
  addPOIMarkers: function() {
    var marker;
    var infobox;

    if (pois.length) {
      for (var i = 0, l = pois.length; i < l; i++) {
        marker = this.createMarker(pois[i]);
        infobox = this.createInfoBox(marker, pois[i]);
        this.attachMapHandlers(marker, i);
      }
    }
  },
  createClusterers: function() {
    clusterer = new MarkerClusterer(map, [], {
      maxZoom: 17,
      styles: clusterStyles
    });
  },
  createMarker: function(poi) {
    var markerType = '<svg class="icon-' + poi.category + ' icon--light">' +
        '<use xlink:href="#svgs--' + poi.category + '"></use>' +
      '</svg>';

    var markerContent = '<div class="marker marker--poi js-marker" data-cat="' + poi.category + '">' +
        '<div class="marker__icon">' + markerType + '</div>' +
          '<p>' + poi.name + '</p>' +
        '</div>';

    var marker = new RichMarker({
      position: new google.maps.LatLng(Number(poi.lat), Number(poi.lng)),
      map: map,
      content: markerContent
    });

    markers.push(marker);

    return marker;
  },
  createInfoBox: function(marker, poi) {
    var walkingTime = '';
    var link = '';

    if (poi.walking_time) {
      walkingTime = '<div class="pois__walking-distance">' +
          '<div class="pois__walking-distance__icon">' +
            '<svg class="icon-walk icon--light">' +
              '<use xlink:href="#svgs--walk"></use>' +
            '</svg>' +
          '</div>' +
          '<div class="pois__walking-distance__text">' +
            '<p>Walking distance</p>' +
            '<p>' + poi.walking_time + '</p>' +
          '</div>' +
        '</div>';
    }

    if (poi.link) {
      link = '<a href="http://' + poi.link + '" target="_blank" class="pois__link">' + poi.link + '</a>';
    }

    var boxContent = '<div class="infobox pois">' +
          '<p class="pois__title">' + poi.name + '</p>' +
          link +
          '<div class="pois__details">' +
            walkingTime +
            '<div class="pois__distance"><p>' + poi.distance + '</p></div>' +
          '</div>' +
        '</div>';

    var myOptions = {
      content: boxContent,
      disableAutoPan: false,
      maxWidth: 0,
      pixelOffset: new google.maps.Size(-145, -362),
      zIndex: null,
      boxStyle: {
        backgroundImage: 'url(' + poi.bg + ')',
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '288px',
        height: '260px',
        padding: '54px 14px 14px 24px',
        position: 'relative'
      },
      closeBoxMargin: '-40px 0 0 0',
      closeBoxURL: 'https://s3-eu-west-1.amazonaws.com/liberdade-terrace/close-btn.png',
      infoBoxClearance: new google.maps.Size(1, 1),
      isHidden: false,
      pane: 'floatPane',
      enableEventPropagation: false
    };

    var infobox = new InfoBox(myOptions);

    infoboxes.push(infobox);
  },
  attachMapHandlers: function(marker, index) {
    var infobox = infoboxes[index];

    google.maps.event.addListener(marker, 'click', function() {
      for (var j = 0, l = infoboxes.length; j < l; j++) {
        markers[j].markerContent_.firstChild.classList.remove('active');
        infoboxes[j].close();
      }

      map.panTo(marker.getPosition());

      marker.markerContent_.firstChild.classList.add('active');
      infobox.open(map, this);
    });

    google.maps.event.addListener(infobox, 'closeclick', function(event) {
      marker.markerContent_.firstChild.classList.remove('active');
    });
  },
  attachFiltersHandlers: function() {
    var filters = document.querySelectorAll('.js-filter');
    var self = this;

    function attachClick(filter) {
      filter.addEventListener('click', function(event) {
        var isActive = this.classList.contains('active');
        var filter = this.dataset.filter;

        if (isActive) {
          this.classList.remove('active');
          activeFilters = activeFilters.filter(function(activeFilter) {
            return activeFilter !== filter;
          });
        } else {
          this.classList.add('active');
          activeFilters.push(filter);
        }

        self.changeMarkerState();
      });
    }


    for (var i = 0, l = filters.length; i < l; i++) {
      attachClick(filters[i]);
    }
  },
  setActiveFilter: function() {
    var filters = document.querySelectorAll('.js-filter');

    for (var i = 0, l = filters.length; i < l; i++) {
      var event = document.createEvent('HTMLEvents');

      event.initEvent('click', true, false);
      filters[i].dispatchEvent(event);
    }
  },
  changeMarkerState: function() {
    var marker;
    var tempEl;
    var markerEl;
    var markerCat;
    var markersArr = [];
    var markersEls = document.querySelectorAll('.js-marker');

    function setDefaultMarkers(defaultStyle) {
      for (var i = 0, l = markersEls.length; i < l; i++) {
        markersEls[i].classList[defaultStyle ? 'add' : 'remove']('marker--blue');
      }
    }

    for (var i = 0, l = markers.length; i < l; i++) {
      marker = markers[i];
      tempEl = document.createElement('div');
      tempEl.innerHTML = marker.getContent();
      markerEl = tempEl.children[0];
      markerCat = markerEl.dataset.cat;

      if (!activeFilters.length) {
        setDefaultMarkers(true);
        marker.setVisible(false);
        // markersArr.push(marker);
      } else {
        setDefaultMarkers(false);

        if (!!~activeFilters.indexOf(markerCat)) {
          marker.setVisible(true);
          markersArr.push(marker);
        } else {
          marker.setVisible(false);
          infoboxes[i].close();
        }
      }
    }

    clusterer.clearMarkers();

    if (markersArr.length) {
      clusterer.addMarkers(markersArr, true);
      clusterer.repaint();
    }
  }
};
